import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "@context/authContext";
import MainLayout from "@components/Layout/MainLayout";

const ProtectedRoute = () => {
  const { token } = useContext(AuthContext);

  return token ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoute;
