import {
  PrimaryButton,
  DangerButton,
  SecondaryButton,
} from "@components/Buttons";
import { Modal, Header } from "semantic-ui-react";
import React, { useState } from "react";
import { IPartialUser } from "src/types/context";
import "./userCard.scss";
import { ConfirmationModal } from "@components/Modals";
import AddUserContent from "./AddUserContent";

interface IUserCardProps {
  firstName: string;
  lastName: string;
  slackId: string;
  isActive: boolean;
  updateUser: (id: string, newData: IPartialUser) => void;
  archiveUser: (id: string) => void;
}

const UserCard = ({
  firstName,
  lastName,
  slackId,
  isActive,
  updateUser,
  archiveUser,
}: IUserCardProps) => {
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);

  const submitData = (userData) => {
    setEditModalOpened(false);
    updateUser(slackId, userData);
  };

  const onArchiveUser = () => archiveUser(slackId);

  return (
    <div className={`user-card ${isActive ? "" : "user-card--inactive"}`}>
      <div className={"user-card__header"}>
        <Header as={"p"}>{firstName}</Header>
        <Header as={"p"}>{lastName}</Header>
        <Header as={"p"}>{slackId}</Header>
      </div>
      <div className={"user-card__options"}>
        <PrimaryButton
          onClick={() => setEditModalOpened(true)}
          content={"Edit"}
          icon={"edit"}
          size={"large"}
        />
        {isActive && (
          <DangerButton
            onClick={() => setIsConfirmModalOpened(true)}
            content={"Archive"}
            icon={"delete"}
            size={"large"}
          />
        )}
      </div>
      <Modal
        onClose={() => setIsModalOpened(false)}
        onOpen={() => setIsModalOpened(true)}
        open={isModalOpened}
        size={"tiny"}
      >
        <Modal.Content>
          <Header size={"large"}>
            {"Error while editing! Check if you provided new name and surname"}
          </Header>
        </Modal.Content>
        <Modal.Actions>
          <SecondaryButton
            content={"OK"}
            onClick={() => setIsModalOpened(false)}
          />
        </Modal.Actions>
      </Modal>
      <ConfirmationModal
        confirmationText={"This will archive the user. Are you sure?"}
        handleConfirmAction={() => onArchiveUser()}
        handleCloseAction={() => setIsConfirmModalOpened(false)}
        isOpen={isConfirmModalOpened}
      />
      <Modal
        onClose={() => setEditModalOpened(false)}
        onOpen={() => setIsModalOpened(true)}
        open={editModalOpened}
        size={"tiny"}
      >
        <AddUserContent
          userData={{ firstName, lastName, slackId }}
          handleSubmit={(data) => submitData(data)}
          closeModal={() => setEditModalOpened(false)}
        />
      </Modal>
    </div>
  );
};

export default UserCard;
