import React, { useContext, useEffect, useState } from "react";
import { QdosContext } from "@context/context";
import UserCard from "./components/UserCard";
import "./users.scss";
import { USERS_STATUS } from "../../types/users";
import { PrimaryButton } from "@components/Buttons";
import { Dropdown, Modal } from "semantic-ui-react";
import AddUserContent from "./components/AddUserContent";

const userOptions = [
  {
    key: "active",
    value: "active",
    text: "Active",
  },
  {
    key: "archived",
    value: "archived",
    text: "Archived",
  },
  {
    key: "all",
    value: "all",
    text: "All",
  },
];

const Users = () => {
  const {
    getUsersAction,
    users,
    addUserAction,
    updateUserAction,
    archiveUserAction,
  } = useContext(QdosContext);

  const [activeOption, setActiveOption] = useState<USERS_STATUS>(
    USERS_STATUS.ACTIVE
  );
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [isModalOpened, setIsModalOpened] = useState(false);

  useEffect(() => {
    !users.length && getUsersAction();
  }, []);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    if (activeOption === USERS_STATUS.ACTIVE) {
      const activeUsers = users.filter((user) => user.isActive);
      setFilteredUsers(activeUsers);
    }
    if (activeOption === USERS_STATUS.ARCHIVED) {
      const archivedUsers = users.filter((user) => !user.isActive);
      setFilteredUsers(archivedUsers);
    }
    if (activeOption === USERS_STATUS.ALL) {
      setFilteredUsers(users);
    }
  }, [users, activeOption]);

  const onSelectChange = (e, { value }) => {
    setActiveOption(value);
  };

  const handleAddUser = async (userData) => {
    addUserAction(userData);
    setIsModalOpened(false);
  };

  return (
    <div className={"users__container"}>
      <h1>{"Users"}</h1>
      <div className={"users__container-options"}>
        <PrimaryButton
          onClick={() => setIsModalOpened(true)}
          content={"Add User"}
          icon={"add user"}
          size={"huge"}
        />
        <div className={"users__option-select"}>
          <Dropdown
            onChange={onSelectChange}
            selection
            options={userOptions}
            value={activeOption}
          />
        </div>
      </div>
      <div className={"flex-list"}>
        {filteredUsers.map((user) => (
          <UserCard
            updateUser={updateUserAction}
            archiveUser={archiveUserAction}
            key={user.id}
            slackId={user.slackId}
            firstName={user.firstName}
            lastName={user.lastName}
            isActive={user.isActive}
          />
        ))}
      </div>
      <Modal
        onClose={() => setIsModalOpened(false)}
        onOpen={() => setIsModalOpened(true)}
        open={isModalOpened}
        size={"tiny"}
      >
        <AddUserContent
          closeModal={() => setIsModalOpened(false)}
          handleSubmit={handleAddUser}
        />
      </Modal>
    </div>
  );
};

export default Users;
