import React, { useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import "./loader.scss";
import { useScrollLock } from "@utils/useScrollLock";

const QdosLoader = () => {
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    lockScroll();

    return () => unlockScroll();
  }, []);

  return (
    <Dimmer active>
      <Loader />
    </Dimmer>
  );
};

export default QdosLoader;
