import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "./datepicker.scss";
import "react-datepicker/dist/react-datepicker.css";

interface IQdosDatePickerProps {
  containerStyles?: string;
  onChange: (date) => void;
  startDate: Date;
}

const QdosDatePicker = ({
  containerStyles,
  onChange,
  startDate,
  ...props
}: IQdosDatePickerProps & ReactDatePickerProps) => {
  return (
    <div className={`datepicker__container ${containerStyles}`}>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export default QdosDatePicker;
