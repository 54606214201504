import React, { useCallback, useContext, useEffect, useState } from "react";
import QdosForm from "./components/QdosForm";
import { QdosContext } from "@context/context";
import QdosCard from "./components/QdosCard";
import { Header, Icon, Modal } from "semantic-ui-react";
import { LinkButton, PrimaryButton } from "@components/Buttons";
import { createPageStructure } from "../PDFView/utils/getPageStructure";
import "./home.scss";
import QdosDatePicker from "@components/DatePicker/DatePicker";

const Home = () => {
  const {
    currentMonthQdoses,
    currentMonthQdosesStats,
    getUsersAction,
    getQdosesForCurrentMonthAction,
    getQdosesStatsForCurrentMonthAction,
    addQdosForCurrentMonthAction,
    editQdosAction,
    removeQdosAction,
    isLoading,
  } = useContext(QdosContext);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    getUsersAction();
    getQdosWithStats();
    getPages();
  }, []);

  useEffect(() => {
    getPages();
  }, [currentMonthQdoses]);

  useEffect(() => {
    getQdosWithStats();
  }, [currentDate]);

  const handleAddQdos = useCallback((data) => {
    setIsModalOpened(false);
    addQdosForCurrentMonthAction(data);
  }, []);

  const getQdosWithStats = () => {
    getQdosesForCurrentMonthAction(
      currentDate.getMonth() + 1,
      currentDate.getFullYear()
    );
    getQdosesStatsForCurrentMonthAction(
      currentDate.getMonth() + 1,
      currentDate.getFullYear()
    );
  };

  const handleEditQdos = useCallback((qdosData, id) => {
    editQdosAction(qdosData, id);
  }, []);

  const handleRemoveQdos = useCallback((id) => {
    removeQdosAction(id);
  }, []);

  const getPages = () => {
    createPageStructure(currentMonthQdoses);
  };

  return (
    <div className={"home__container"}>
      <h1>{"Qdos List"}</h1>
      <QdosDatePicker
        dateFormat="MMMM yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        showFourColumnMonthYearPicker
        startDate={currentDate}
        onChange={(date) => setCurrentDate(date)}
      />
      <div className={"home__container-options"}>
        <PrimaryButton
          onClick={() => setIsModalOpened(true)}
          content={"Add Qdos"}
          icon={"add circle"}
        />
        <LinkButton
          linkTo={"/pdf-view"}
          text={"View PDF"}
          data={{ qdosData: currentMonthQdoses, date: currentDate }}
        >
          <Icon name={"file pdf"} />
        </LinkButton>
      </div>
      <div className={"qdoses__container"}>
        <div className={"flex-list"}>
          {currentMonthQdoses.map((qdos) => (
            <QdosCard
              key={qdos.qdos_id}
              qdos={qdos}
              handleEdit={handleEditQdos}
              handleRemove={handleRemoveQdos}
            />
          ))}
          {!currentMonthQdoses.length && !isLoading && (
            <div className={"qdos-empty-container"}>
              <Header
                size={"medium"}
                color={"orange"}
              >
                {"No Qdos for this month"}
              </Header>
            </div>
          )}
        </div>
        <div className={"qdos__container-stats"}>
          <Header as={"p"}>
            {"Prize per qdos"} {currentMonthQdosesStats.prizeValue}
          </Header>
          <div className={"stats__container"}>
            {currentMonthQdosesStats.usersList.map((user) => (
              <div
                className={"qdos__stat-user"}
                key={user.user_id}
              >
                <span className={"qdos__stat-user-cell"}>
                  {user.user_for_name} {user.user_for_surname}
                </span>
                <span className={"qdos__stat-user-cell"}>{user.count}</span>
                <span className={"qdos__stat-user-cell"}>{user.prize}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        onClose={() => setIsModalOpened(false)}
        onOpen={() => setIsModalOpened(true)}
        open={isModalOpened}
        size={"tiny"}
      >
        <QdosForm
          closeModal={() => setIsModalOpened(false)}
          handleSubmit={handleAddQdos}
        />
      </Modal>
    </div>
  );
};

export default Home;
