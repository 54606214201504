import { PrimaryButton, SecondaryButton } from "@components/Buttons";
import { Modal, Form, Message } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { IPartialUser } from "src/types/context";
import { containsAnyLetters } from "@utils/strings";

interface IAddUserContent {
  userData?: IPartialUser;
  handleSubmit?: (userData: IPartialUser) => void;
  closeModal: () => void;
}

const AddUserContent = ({
  userData,
  handleSubmit,
  closeModal,
}: IAddUserContent) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    slackId: "",
  });
  const [isFormValid, setIsFormValid] = useState(true);

  const handleInputChange = (e, { name, value }) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (userData) {
      setFormData({
        firstName: userData.firstName,
        lastName: userData.lastName,
        slackId: userData.slackId,
      });
    }
  }, []);

  const handleSubmitData = () => {
    if (
      !containsAnyLetters(formData.firstName) ||
      !containsAnyLetters(formData.lastName) ||
      !containsAnyLetters(formData.slackId)
    ) {
      setIsFormValid(false);
      return;
    }
    setIsFormValid(true);
    handleSubmit(formData);
  };

  return (
    <>
      <Modal.Header>{"Add new user"}</Modal.Header>
      <Modal.Content>
        <Form
          size={"huge"}
          error={!isFormValid}
        >
          <Form.Input
            label={"Name"}
            required
            placeholder={"First Name"}
            name={"firstName"}
            value={formData.firstName}
            onChange={handleInputChange}
          />
          <Form.Input
            label={"Last Name"}
            required
            placeholder={"Last Name"}
            name={"lastName"}
            value={formData.lastName}
            onChange={handleInputChange}
          />
          <Form.Input
            label={"Slack ID"}
            required
            placeholder={"Slack ID"}
            name={"slackId"}
            value={formData.slackId}
            onChange={handleInputChange}
          />
          <Message
            error
            header={"Fields are empty"}
            list={["Fill form with user name, last name and slack ID"]}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <PrimaryButton
          content={"Submit"}
          onClick={handleSubmitData}
        />
        <SecondaryButton
          content={"Cancel"}
          onClick={closeModal}
        />
      </Modal.Actions>
    </>
  );
};

export default AddUserContent;
