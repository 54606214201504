import React, { useContext, useState, useEffect } from "react";
import Menu from "@components/Menu/Menu";
import "./layout.scss";
import { Outlet } from "react-router-dom";
import { ConfirmationModal } from "@components/Modals";
import { QdosContext } from "@context/context";

interface IMainLayoutProps {
  children?: JSX.Element;
}

const MainLayout = ({ children }: IMainLayoutProps) => {
  const { isLoading, actionError, clearActionError } = useContext(QdosContext);
  const [errorModalOpened, setErrorModalOpened] = useState(false);

  useEffect(() => {
    if (!isLoading && actionError) {
      setErrorModalOpened(true);
    }
  }, [isLoading, actionError]);

  return (
    <div className={"main__container"}>
      <div className={"layout-container"}>
        <div className={"layout__topbar"}></div>
        <div className={"layout__sidebar"}>
          <Menu />
        </div>
        <div className={"layout__content"}>
          {children ? children : <Outlet />}
          <ConfirmationModal
            confirmationText={actionError}
            handleCloseAction={() => {
              setErrorModalOpened(false);
              clearActionError();
            }}
            isOpen={errorModalOpened}
          />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
