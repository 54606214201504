import React from "react";
import { PrimaryButton, SecondaryButton } from "@components/Buttons";
import { Modal } from "semantic-ui-react";

interface IConfirmationModalProps {
  confirmationText: string;
  buttonTextConfirm?: string;
  buttonTextReject?: string;
  handleConfirmAction?: () => void;
  handleCloseAction: () => void;
  isOpen: boolean;
}

const ConfirmationModal = ({
  confirmationText,
  buttonTextReject = "Cancel",
  buttonTextConfirm = "OK",
  handleConfirmAction,
  handleCloseAction,
  isOpen,
}: IConfirmationModalProps) => {
  return (
    <Modal
      onClose={handleCloseAction}
      open={isOpen}
      size={"tiny"}
    >
      <Modal.Header>{confirmationText}</Modal.Header>
      <Modal.Actions>
        {handleConfirmAction && (
          <PrimaryButton
            onClick={handleConfirmAction}
            content={buttonTextConfirm}
          />
        )}
        <SecondaryButton
          onClick={handleCloseAction}
          content={buttonTextReject}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmationModal;
