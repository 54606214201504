import React from "react";
import { Link } from "react-router-dom";
import { Button, ButtonProps } from "semantic-ui-react";
import "./buttons.scss";

interface ILinkButtonProps {
  text: string;
  linkTo: string;
  customClass?: string;
  data?: unknown;
  children?: React.ReactElement;
}

const LinkButton = ({
  text,
  linkTo,
  customClass,
  data,
  children,
  ...props
}: ILinkButtonProps & ButtonProps) => (
  <Link
    to={linkTo}
    state={data}
    className={`link-button ${customClass ? customClass : ""}`}
  >
    <Button
      basic
      size={"big"}
      fluid
      color={"blue"}
      {...props}
    >
      {children}
      {text}
    </Button>
  </Link>
);

export default LinkButton;
