import React from "react";
import { Button, ButtonProps } from "semantic-ui-react";

const DangerButton = ({ ...props }: ButtonProps) => (
  <Button
    size="big"
    color="red"
    {...props}
  />
);

export default DangerButton;
