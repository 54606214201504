import Layout from "./components/Layout/Layout";
import React from "react";
import "./app.scss";
import "semantic-ui-css/semantic.min.css";
import { Provider } from "@context/context";
import { AuthProvider } from "@context/authContext";
import { BrowserRouter } from "react-router-dom";

const App = () => (
  <BrowserRouter>
    <Provider>
      <AuthProvider>
        <Layout />
      </AuthProvider>
    </Provider>
  </BrowserRouter>
);

export default App;
