import React, { createContext, useState } from "react";
import {
  updateUser,
  getQdosForCurrentMonth,
  getQdosesStatsForCurrentMonth,
} from "@api";
import {
  IPartialUser,
  IQdosData,
  IQdosStats,
  IUser,
  QdosContextType,
  QdosType,
} from "src/types/context";
import {
  archiveUserService,
  getUsersService,
  addUserService,
  addQdosForCurrentMonthService,
  editQdosService,
} from "@services";
import { removeQdosService } from "../services/qdosesService";

export const QdosContext = createContext<QdosContextType>(null);

export const Provider = ({ children }) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [currentMonthQdoses, setCurrentMonthQdos] = useState<IQdosData[]>([]);
  const [currentMonthQdosesStats, setCurrentMonthQdosStats] =
    useState<IQdosStats>({ usersList: [], prizeValue: null });
  const [isLoading, setIsLoading] = useState(false);
  const [actionError, setActionError] = useState("");

  const setLoading = (status) => setIsLoading(!!status);

  const getUsersAction = async () => {
    setIsLoading(true);
    try {
      const response = await getUsersService();

      if (response.error || response.status >= 400) {
        setActionError(response.error);
      }
      setUsers(response.data);
    } catch (error) {
      throw error;
    }
    setIsLoading(false);
  };

  const updateUserAction = async (userId: string, newData) => {
    setIsLoading(true);
    const updateUserRes = await updateUser(userId, newData);

    if (updateUserRes.status >= 400) {
      setActionError(updateUserRes.error);
    }
    await getUsersAction();
    setIsLoading(false);
  };

  const archiveUserAction = async (userId: string) => {
    setIsLoading(true);
    try {
      const response = await archiveUserService(userId);

      if (response.error || response.status >= 400) {
        setActionError(response.error);
      }
      await getUsersAction();
    } catch (error) {
      throw error;
    }
    setIsLoading(false);
  };

  const addUserAction = async (userData: IPartialUser) => {
    setIsLoading(true);
    try {
      const response = await addUserService(userData);

      if (response.error || response.status >= 400) {
        setIsLoading(false);
        setActionError(response.error);
        return;
      }
      await getUsersAction();
    } catch (error) {
      throw error;
    }
    setIsLoading(false);
  };

  const getQdosesForCurrentMonthAction = async (
    month: number,
    year: number
  ) => {
    setIsLoading(true);
    const qdosesPerMonthRes = await getQdosForCurrentMonth(month, year);
    if (qdosesPerMonthRes) {
      setCurrentMonthQdos(qdosesPerMonthRes.data);
      setIsLoading(false);
      return;
    }
  };

  const getQdosesStatsForCurrentMonthAction = async (
    month: number,
    year: number
  ) => {
    setIsLoading(true);
    const response = await getQdosesStatsForCurrentMonth(month, year);

    if (response.data) {
      setCurrentMonthQdosStats(response.data);
      setIsLoading(false);
      return;
    }
  };

  const addQdosForCurrentMonthAction = async (qdosData: QdosType) => {
    setIsLoading(true);
    const response = await addQdosForCurrentMonthService(qdosData);

    if (response.error || response.status >= 400) {
      setActionError(response.error);
    }

    await getQdosesForCurrentMonthAction(new Date().getMonth() + 1, 2022);
  };

  const editQdosAction = async (qdosData: QdosType, qdosId) => {
    setIsLoading(true);
    const response = await editQdosService(qdosData, qdosId);

    if (response.error || response.status >= 400) {
      setActionError(response.error);
    }

    await getQdosesForCurrentMonthAction(new Date().getMonth() + 1, 2022);
  };

  const removeQdosAction = async (qdosId) => {
    setIsLoading(true);
    const response = await removeQdosService(qdosId);

    if (response.error || response.status >= 400) {
      setActionError(response.error);
    }

    await getQdosesForCurrentMonthAction(new Date().getMonth() + 1, 2022);
    setIsLoading(false);
  };

  const clearActionError = () => setActionError("");

  return (
    <QdosContext.Provider
      value={{
        users,
        currentMonthQdoses,
        currentMonthQdosesStats,
        getUsersAction,
        updateUserAction,
        archiveUserAction,
        addUserAction,
        isLoading,
        setLoading,
        getQdosesForCurrentMonthAction,
        getQdosesStatsForCurrentMonthAction,
        addQdosForCurrentMonthAction,
        editQdosAction,
        removeQdosAction,
        actionError,
        clearActionError,
      }}
    >
      {children}
    </QdosContext.Provider>
  );
};
