import { IQdosData } from "src/types/context";

export interface IQdosType {
  type: string;
  name: string;
}

export type IPageStructure = Array<IQdosData & IQdosType>;

export const createPageStructure = (qdosData: IQdosData[]) => {
  const usersIds = qdosData.map((qdos: IQdosData) => qdos.from_user_id);
  const uniqueUsersIds = Array.from(new Set(usersIds));

  const usersQdoses = uniqueUsersIds.map((user) => {
    return qdosData.filter((qdos) => qdos.from_user_id === user);
  });

  const pdfCollection = usersQdoses.map((el: IPageStructure) => {
    const authorName = el[0].creator_user_name;
    const titleObject = { type: "authorTitle", name: authorName };
    return [titleObject].concat(el);
  });

  const sortedCollection = [...pdfCollection].sort(
    (a, b) => b.length - a.length
  );

  return sortedCollection.flat();
};
