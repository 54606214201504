import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { Home, PDFView, Users, Login } from "@pages";
import "./layout.scss";
import { QdosContext } from "@context/context";
import { QdosLoader } from "@components/Loader";
import EmptyLayout from "@components/Layout/EmptyLayout";
import { AuthContext } from "@context/authContext";
import ProtectedRoute from "@components/ProtectedRoute/ProtectedRoute";

const Layout = () => {
  const { isLoading } = useContext(QdosContext);
  const { handleLoginAction } = useContext(AuthContext);

  return (
    <>
      <Routes>
        <Route path="/">
          <Route element={<EmptyLayout />}>
            <Route
              index
              element={
                <Login handleLogin={(data) => handleLoginAction(data)} />
              }
            />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route
              path={"/home"}
              element={<Home />}
            />
            <Route
              path={"/users"}
              element={<Users />}
            />
            <Route
              path={"/pdf-view"}
              element={<PDFView />}
            />
          </Route>
        </Route>
      </Routes>
      {isLoading && <QdosLoader />}
    </>
  );
};

export default Layout;
