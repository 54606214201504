import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import FrontPage from "./components/FrontPage";
import { IPageStructure } from "./utils/getPageStructure";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8V1s.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1s.ttf",
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Poppins",
  },
  titlePageContainer: {
    width: "100%",
    borderWidth: 1,
    backgroundColor: "#243B81",
    paddingVertical: 40,
    textAlign: "center",
  },
  section: {
    marginVertical: 5,
  },
  mainContainer: {
    width: "100%",
    height: "100%",
    flexDirection: "row",
  },
  contentContainer: {
    flex: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  rightContainer: {
    flex: 1,
    backgroundColor: "#337AC0",
  },
  text: {
    fontSize: 14,
    textAlign: "justify",
  },
  textLine: {
    marginBottom: 15,
    fontSize: 28,
    lineHeight: 1.25,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  qdosContainer: {
    width: "80%",
  },
  userFrom: {
    fontSize: 72,
    color: "#fff",
  },
  boldFont: {
    fontWeight: 600,
  },
});

interface IPDFDoc {
  pagesData: IPageStructure;
  qdosDate: Date;
}

const PDFDoc = ({ pagesData, qdosDate }: IPDFDoc) => {
  return (
    <Document>
      <FrontPage date={qdosDate} />
      {pagesData.map((page) => {
        if (page.type) {
          return (
            <Page
              orientation={"landscape"}
              size={"A4"}
              style={styles.page}
              key={page.qdos_id}
            >
              <View style={styles.titlePageContainer}>
                <Text style={styles.userFrom}>From {page.name} </Text>
              </View>
            </Page>
          );
        }

        if (!page.type) {
          return (
            <Page
              orientation={"landscape"}
              size={"A4"}
              style={styles.page}
              key={page.qdos_id}
            >
              <View style={styles.mainContainer}>
                <View style={styles.contentContainer}>
                  <View
                    style={styles.qdosContainer}
                    key={page.qdos_id}
                  >
                    <Text style={styles.textLine}>
                      <Text style={styles.boldFont}>{"To: "}</Text>
                      {page.usersFor.map((user, idx) => (
                        <Text key={user.slackId}>
                          {user.firstName} {user.lastName?.charAt(0)}
                          {user.lastName && "."}
                          {idx !== page.usersFor.length - 1 ? ", " : ""}
                        </Text>
                      ))}
                    </Text>
                    <Text style={styles.textLine}>
                      <Text style={styles.boldFont}>{"From: "}</Text>
                      <Text>{page.creator_user_name}</Text>
                    </Text>
                    <Text style={[styles.textLine, { marginBottom: 0 }]}>
                      <Text style={styles.boldFont}>{"For: "}</Text>
                      <Text>
                        {page.qdos_title} - {page.description}{" "}
                      </Text>
                    </Text>
                  </View>
                </View>

                <View style={styles.rightContainer}></View>
              </View>
            </Page>
          );
        }
      })}
    </Document>
  );
};
export default PDFDoc;
