import React from "react";
import { Button, ButtonProps } from "semantic-ui-react";

const PrimaryButton = ({ ...props }: ButtonProps) => (
  <Button
    primary
    size={"big"}
    color={"blue"}
    {...props}
  />
);

export default PrimaryButton;
