import React, { useState, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { authLoginService } from "@services";
import { QdosContext } from "@context/context";

const initialState = {
  token: null,
  handleLoginAction: null,
  error: null,
};

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [token, setToken] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const { setLoading } = useContext(QdosContext);
  const navigate = useNavigate();

  const handleLoginAction = async (password) => {
    setLoading(true);
    try {
      const response = await authLoginService(password);
      if (response.error || response.status >= 400) {
        setLoginError(response.error);
      }
      const loginToken = response.token;

      if (loginToken) {
        setToken(loginToken);
        navigate("/home");
      }
    } catch (error) {
      throw error;
    }
    setLoading(false);
  };

  const handleLogout = () => {
    setToken(null);
  };

  const values = {
    token,
    handleLoginAction,
    onLogout: handleLogout,
    error: loginError,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
