export const resolve = async (promise) => {
  const resolved = {
    data: null,
    error: null,
    status: null,
  };

  try {
    const promiseResponse = await promise;
    const responseJson = await promiseResponse.json();
    resolved.data = responseJson.data;
    resolved.status = responseJson.status;

    if (responseJson.status >= 400) {
      resolved.error = responseJson.error;
      resolved.data = null;
    }
  } catch (e) {
    throw e;
  }

  return resolved;
};
