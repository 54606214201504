import { API_URL } from "../constants/environment";
import { resolve } from "./resolve";

export const getUsers = async () => await resolve(fetch(`${API_URL}/users`));

export const updateUser = async (userId, newData) =>
  await resolve(
    fetch(`${API_URL}/users/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: newData.firstName,
        surname: newData.lastName,
        slackId: newData.slackId,
      }),
    })
  );

export const archiveUser = async (userId) =>
  await resolve(
    fetch(`${API_URL}/users/active/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ isActive: false }),
    })
  );

export const addUser = async (userData) =>
  await resolve(
    fetch(`${API_URL}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: userData.firstName,
        lastName: userData.lastName,
        slackId: userData.slackId,
      }),
    })
  );
