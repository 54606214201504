import { addUser, archiveUser, getUsers } from "@api";
import { IPartialUser, IUser } from "src/types/context";

export interface GetUsersResponse {
  status: number;
  error?: string;
  data?: IUser[];
}

export interface ArchiveUserResponse {
  status: number;
  error?: string;
  message?: string;
}

export interface AddUserResponse {
  status: number;
  error?: string;
  data: null;
}

export const getUsersService = async (): Promise<GetUsersResponse> => {
  const response = await getUsers();

  if (response.status >= 400) {
    return {
      status: response.status,
      error: response.error,
    };
  }

  return {
    status: response.status,
    data: response.data,
    error: "",
  };
};

export const archiveUserService = async (
  userId: string
): Promise<ArchiveUserResponse> => {
  const response = await archiveUser(userId);

  if (response.status >= 400) {
    return {
      status: response.status,
      error: response.error,
    };
  }

  return {
    status: response.status,
    error: "",
  };
};

export const addUserService = async (
  userData: IPartialUser
): Promise<AddUserResponse> => {
  const response = await addUser(userData);

  if (response.status >= 400) {
    return {
      data: null,
      status: response.status,
      error: response.error,
    };
  }

  return {
    status: response.status,
    data: null,
    error: "",
  };
};
