import React from "react";
import { Button, ButtonProps } from "semantic-ui-react";

const SecondaryButton = ({ ...rest }: ButtonProps) => (
  <Button
    size={"big"}
    color={"grey"}
    {...rest}
  />
);

export default SecondaryButton;
