import React from "react";
import { LinkButton } from "@components/Buttons";
import "./menu.scss";

const Menu = () => (
  <div className="menu__container">
    <LinkButton
      customClass={"menu-link"}
      linkTo="/home"
      text={"Qdos List"}
    />
    <LinkButton
      customClass={"menu-link"}
      linkTo="/users"
      text={"Users"}
    />
  </div>
);

export default Menu;
