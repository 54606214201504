import React, { useState, useCallback } from "react";
import { Modal } from "semantic-ui-react";
import { IQdosData } from "src/types/context";
import "./qdosCard.scss";
import QdosForm from "./QdosForm";
import { DangerButton, PrimaryButton } from "@components/Buttons";
import { ConfirmationModal } from "@components/Modals";

interface IQdosCardProps {
  qdos: IQdosData;
  handleEdit: (qdosData, qdosId) => void;
  handleRemove: (qdosId) => void;
}

const QdosCard = ({ qdos, handleEdit, handleRemove }: IQdosCardProps) => {
  const [isEditOpened, setIsEditOpened] = useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);

  const handleQdosChange = useCallback((e) => {
    setIsEditOpened(false);
    handleEdit(e, qdos.qdos_id);
  }, []);

  const handleQdosRemove = useCallback(() => {
    setIsConfirmModalOpened(false);
    handleRemove(qdos.qdos_id);
  }, []);

  return (
    <div className={"qdos-card"}>
      <div className={"qdos-card__segment"}>
        <p>
          {"For: "}
          {qdos.usersFor.map((user, idx, arr) => (
            <span
              className={"qdos-card__user-name"}
              key={user.slackId}
            >
              {user.firstName} {user.lastName}
              {idx !== arr.length - 1 ? ", " : ""}
            </span>
          ))}
        </p>
      </div>
      <div className={"qdos-card__segment"}>
        <p>
          {"From:"} {qdos.creator_user_name} {qdos.creator_user_surname}
        </p>
      </div>
      <div className={"qdos-title"}>
        <h2>{qdos.qdos_title}</h2>
      </div>
      <div className={"qdos-description"}>
        <p>{qdos.description}</p>
      </div>
      <div className={"qdos-options"}>
        <PrimaryButton
          content={"Edit"}
          labelPosition={"left"}
          icon={"edit"}
          onClick={() => setIsEditOpened(true)}
        />
        <DangerButton
          icon={"delete"}
          labelPosition="left"
          content={"Delete"}
          onClick={() => setIsConfirmModalOpened(true)}
        />
      </div>
      <Modal
        onClose={() => setIsEditOpened(false)}
        onOpen={() => setIsEditOpened(true)}
        open={isEditOpened}
        size={"tiny"}
      >
        <QdosForm
          closeModal={() => setIsEditOpened(false)}
          handleSubmit={handleQdosChange}
          isEdited
          editData={{
            from: qdos.from_user_id,
            for: qdos.usersFor.map((user) => user.slackId),
            title: qdos.qdos_title,
            description: qdos.description,
          }}
        />
      </Modal>
      <ConfirmationModal
        confirmationText={"This will remove Qdos. Are you sure?"}
        handleConfirmAction={handleQdosRemove}
        handleCloseAction={() => setIsConfirmModalOpened(false)}
        isOpen={isConfirmModalOpened}
      />
    </div>
  );
};

export default QdosCard;
