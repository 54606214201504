import { logUser } from "@api";

export const authLoginService = async (
  password: string
): Promise<{
  token: boolean;
  error: string;
  status: number;
}> => {
  const response = await logUser(password);
  if (response.status >= 400) {
    return {
      token: null,
      error: response.error,
      status: response.status,
    };
  }

  return {
    token: response.data.token,
    error: null,
    status: response.status,
  };
};
