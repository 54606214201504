import React, { useContext, useState } from "react";
import { PrimaryButton } from "@components/Buttons";
import { Form, Header, Message } from "semantic-ui-react";
import "./login.scss";
import { AuthContext } from "@context/authContext";

interface ILoginProps {
  handleLogin: (data) => void;
}

const Login = ({ handleLogin }: ILoginProps) => {
  const [password, setPassword] = useState("");
  const { error } = useContext(AuthContext);

  const handleChange = (e, { value }) => setPassword(value);

  return (
    <div className={"login__container"}>
      <Header as={"h1"}>{"Qdos Manager Login"}</Header>
      <Form error={!!error}>
        <Form.Field>
          <Form.Input
            fluid
            type={"password"}
            placeholder={"Password"}
            value={password}
            onChange={handleChange}
          />
          <Message
            error
            header={"Authentication error"}
            list={[error]}
          />
        </Form.Field>
        <PrimaryButton
          content={"Login"}
          onClick={() => handleLogin(password)}
        />
      </Form>
    </div>
  );
};

export default Login;
