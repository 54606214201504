import React, { useContext, useEffect, useState } from "react";
import { PrimaryButton, SecondaryButton } from "@components/Buttons";
import { Modal, Form, Message } from "semantic-ui-react";
import { QdosType } from "src/types/context";
import { QdosContext } from "@context/context";
import { getStandardizedUsers } from "@utils/users";
import { containsAnyLetters } from "@utils/strings";

interface IDropdownOption {
  id: number;
  text: string;
  value: number;
}

interface IFormData {
  from: string;
  for: string[];
  title: string;
  description: string;
}

interface IQdosForm {
  handleSubmit?: (qdosData: QdosType) => void;
  closeModal: () => void;
  isEdited?: boolean;
  editData?: IFormData;
}

const QdosForm = ({
  handleSubmit,
  closeModal,
  isEdited,
  editData,
}: IQdosForm) => {
  const { users } = useContext(QdosContext);
  const [formData, setFormData] = useState<IFormData>({
    from: null,
    for: [],
    title: "",
    description: "",
  });
  const [usersDropdown, setUsersList] = useState<IDropdownOption[]>([]);
  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    const standardizedUsers = getStandardizedUsers(users);
    setUsersList(standardizedUsers);
  }, [users]);

  useEffect(() => {
    if (editData) {
      setFormData({
        from: editData.from,
        for: editData.for,
        description: editData.description,
        title: editData.title,
      });
    }
  }, []);

  const handleInputChange = (e, { name, value }) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDropdownChange = (e, { value }) => {
    setFormData({
      ...formData,
      for: [...value],
    });
  };

  const handleFromUserDropdownChange = (e, { value }) => {
    setFormData({
      ...formData,
      from: value,
    });
  };

  const handleSubmitData = () => {
    if (
      !containsAnyLetters(formData.title) ||
      !containsAnyLetters(formData.description) ||
      !formData.from ||
      !formData.for.length
    ) {
      setIsFormValid(false);
      return;
    }
    handleSubmit(formData);
  };

  return (
    <>
      <Modal.Header>{isEdited ? "Edit Qdos" : "Add Qdos"}</Modal.Header>
      <Modal.Content>
        <Form
          size={"huge"}
          error={!isFormValid}
        >
          <Form.Dropdown
            label={"For who"}
            required
            multiple
            search
            fluid
            onChange={handleDropdownChange}
            options={usersDropdown}
            value={formData.for}
            selection
          />
          <Form.Dropdown
            label={"From who"}
            required
            search
            fluid
            onChange={handleFromUserDropdownChange}
            options={usersDropdown}
            value={formData.from}
            selection
          />
          <Form.Input
            label={"Title"}
            required
            name={"title"}
            value={formData.title}
            onChange={handleInputChange}
          />
          <Form.TextArea
            label={"Description"}
            required
            value={formData.description}
            name={"description"}
            onChange={handleInputChange}
          />
          <Message
            error
            header={"Fields are empty"}
            list={["Fill form with required fields"]}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <SecondaryButton
          content={"Cancel"}
          onClick={closeModal}
        />
        <PrimaryButton
          content={"Submit"}
          onClick={handleSubmitData}
        />
      </Modal.Actions>
    </>
  );
};

export default QdosForm;
