import { QdosType } from "src/types/context";
import { API_URL } from "../constants/environment";
import { resolve } from "./resolve";

export const getQdosForCurrentMonth = async (month, year) =>
  await resolve(fetch(`${API_URL}/qdoses/date/${year}-${month}`));

export const addQdosForCurrentMonth = async (qdosData: QdosType) =>
  await resolve(
    fetch(`${API_URL}/qdoses`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        description: qdosData.description,
        title: qdosData.title,
        userFrom: qdosData.from,
        usersFor: qdosData.for,
      }),
    })
  );

export const editQdos = async (qdosData: QdosType, qdosId: number) =>
  await resolve(
    fetch(`${API_URL}/qdoses/${qdosId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        description: qdosData.description,
        title: qdosData.title,
        userFrom: qdosData.from,
        usersFor: qdosData.for,
      }),
    })
  );

export const removeQdos = async (qdosId: number) =>
  await resolve(
    fetch(`${API_URL}/qdoses/${qdosId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
  );

export const getQdosesStatsForCurrentMonth = async (
  month: number,
  year: number
) => await resolve(fetch(`${API_URL}/qdoses/month/stats/${year}-${month}`));
