import React, { useEffect, useState } from "react";
import PDFDoc from "./PDFDoc";
import { PDFViewer } from "@react-pdf/renderer";
import "./pdfView.scss";
import { useLocation } from "react-router-dom";
import { createPageStructure } from "./utils/getPageStructure";
import { IQdosData } from "src/types/context";
import { QdosLoader } from "@components/Loader";

interface ILocationState {
  date: Date;
  qdosData: IQdosData[];
}

const PDFView = () => {
  const {
    state: { date, qdosData },
  } = useLocation();
  const [pagesData, setPagesData] = useState(null);
  useEffect(() => {
    if (qdosData) {
      const pagesStructure = createPageStructure(qdosData);
      setPagesData(pagesStructure);
    }
  }, [qdosData]);

  return (
    <div className={"pdfview__container"}>
      {!pagesData ? (
        <QdosLoader />
      ) : (
        <PDFViewer
          style={{
            width: "100%",
            height: window.innerHeight,
          }}
        >
          <PDFDoc
            pagesData={pagesData}
            qdosDate={date}
          />
        </PDFViewer>
      )}
    </div>
  );
};

export default PDFView;
