import React from "react";
import { Outlet } from "react-router-dom";

interface IEmptyLayoutProps {
  children?: JSX.Element;
}

const EmptyLayout = ({ children }: IEmptyLayoutProps) => {
  return (
    <div className={"empty-layout"}>{children ? children : <Outlet />}</div>
  );
};

export default EmptyLayout;
