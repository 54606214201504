import { API_URL } from "../constants/environment";
import { resolve } from "./resolve";

export const logUser = async (password: string) =>
  await resolve(
    fetch(`${API_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
      }),
    })
  );
