import { addQdosForCurrentMonth, editQdos, removeQdos } from "@api";
import { QdosType } from "src/types/context";

export interface AddQdosResponse {
  status: number;
  error?: string;
  data: null;
}

export const addQdosForCurrentMonthService = async (
  qdosData: QdosType
): Promise<AddQdosResponse> => {
  const response = await addQdosForCurrentMonth(qdosData);

  if (response.status >= 400) {
    return {
      data: null,
      status: response.status,
      error: response.error,
    };
  }

  return {
    status: response.status,
    data: null,
    error: "",
  };
};

export const editQdosService = async (
  qdosData: QdosType,
  qdosId: number
): Promise<AddQdosResponse> => {
  const response = await editQdos(qdosData, qdosId);

  if (response.status >= 400) {
    return {
      data: null,
      status: response.status,
      error: response.error,
    };
  }

  return {
    status: response.status,
    data: null,
    error: "",
  };
};

export const removeQdosService = async (
  qdosId: number
): Promise<AddQdosResponse> => {
  const response = await removeQdos(qdosId);

  if (response.status >= 400) {
    return {
      data: null,
      status: response.status,
      error: response.error,
    };
  }

  return {
    status: response.status,
    data: null,
    error: "",
  };
};
