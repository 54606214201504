import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { getCurrentMonthName } from "@utils/dates";
import Logo from "@images/solveq_logo.png";

const styles = StyleSheet.create({
  frontPage: {
    backgroundColor: "#ffffff",
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleContainer: {
    flex: 2,
  },
  title: {
    fontSize: 44,
  },
  logoContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    position: "relative",
    justifyContent: "center",
  },
  subtitle: {
    fontFamily: "Poppins",
    fontSize: 32,
    marginBottom: 8,
  },
  bottomText: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
});

interface IFrontPageProps {
  date: Date;
}

const FrontPage = ({ date }: IFrontPageProps) => (
  <Page
    orientation={"landscape"}
    size={"A4"}
    style={styles.frontPage}
  >
    <View style={styles.titleContainer}>
      <View style={styles.logoContainer}>
        <Image src={Logo} />
      </View>
    </View>
    <View style={styles.bottomText}>
      <Text style={styles.subtitle}>QDOS</Text>
      <Text style={styles.subtitle}>
        {getCurrentMonthName(date)} {date.getFullYear()}
      </Text>
    </View>
  </Page>
);

export default FrontPage;
